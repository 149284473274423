import {
  init as SentryInit,
  BrowserTracing as SentryBrowserTracing,
  setContext as SentrySetContext,
  vueRouterInstrumentation as SentryVueRouterInstrumentation,
} from '@sentry/vue';
import LogRocket from 'logrocket';
// Vue/App level imports
import Vue from 'vue';
import { Threeflow } from '@watchtowerbenefits/shared-components';
import { initializeSegment } from '@watchtowerbenefits/es-utils-public';
import {
  LfcApiTable,
  LfcApiTableHead,
  LfcApiTableParentRow,
  LfcApiTablePagination,
  LfcMultiSelect,
} from '@watchtowerbenefits/lfc-components';
import Icon from 'vue-awesome/components/Icon.vue';
/* eslint-disable import/no-extraneous-dependencies */
import lang from 'element-ui/lib/locale/lang/en.js';
import locale from 'element-ui/lib/locale/index.js';
/* eslint-enable import/no-extraneous-dependencies */
import { mapWritableState, createPinia, PiniaVuePlugin } from 'pinia';
import { useLaunchDarkly } from '@/stores/launchDarkly.js';
import { useCoreApi } from '@/stores/coreApi.js';
import LoadScript from 'vue-plugin-load-script';
import filters from '@/utils/filters.js';
import {
  activateAxios,
  addLogRocketURLToHeaders,
  inactiveSignOut,
  setStorage,
} from '@/utils/services.js';
// Components
import AppTooltip from '@/components/AppTooltip.vue';
import AppContactSupport from '@/components/Ctas/ContactSupport.vue';
import AppDatePicker from '@/components/AppDatePicker.vue';
// Configs
import { parseLaunchDarklyConfiguration, initializeLaunchDarkly } from '@/utils/launchDarklyConfig.js';
import { getHealthCheck } from '@/apis/healthCheck.js';
import router from '@/router.js';
import App from '@/App.vue';
// logrocket
import { logrocketPlugin } from '@/utils/piniaPlugins.js';
// Icons
import 'svg-innerhtml';
import 'vue-awesome/icons/check.js';
import 'vue-awesome/icons/chevron-down.js';
import 'vue-awesome/icons/chevron-right.js';
import 'vue-awesome/icons/download.js';
import 'vue-awesome/icons/edit.js';
import 'vue-awesome/icons/spinner.js';
import 'vue-awesome/icons/users.js';
// icons for shared repo
import 'vue-awesome/icons/angle-left.js';
import 'vue-awesome/icons/angle-right.js';
import 'vue-awesome/icons/angle-up.js';
import 'vue-awesome/icons/angle-down.js';
import 'vue-awesome/icons/caret-down.js';
import 'vue-awesome/icons/exclamation-triangle.js';
import 'vue-awesome/icons/eye.js';
import 'vue-awesome/icons/file-download.js';
import 'vue-awesome/icons/pencil-alt.js';
import 'vue-awesome/icons/plus.js'; // lfc-components
import 'vue-awesome/icons/reply.js';
import 'vue-awesome/icons/times.js';
import 'vue-awesome/icons/trash-alt.js';
// styles
import './assets/styles/main.scss';
import '@watchtowerbenefits/lfc-components/dist/style.css';
import { config } from '@/utils/config.js';
import { canUseThirdParty } from './utils/general.js';

let healthCheckCompleted = false;
let launchDarklyConfigData;
const {
  VUE_APP_VERSION: appVersion,
  VUE_APP_ENV: appEnvironment,
} = config;

Vue.config.productionTip = false;

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Icon', Icon);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('AppTooltip', AppTooltip);
Vue.component('AppCtaContactSupport', AppContactSupport);
Vue.component('AppDatePicker', AppDatePicker);
Vue.component('LfcApiTable', LfcApiTable);
Vue.component('LfcApiTableHead', LfcApiTableHead);
Vue.component('LfcApiTableParentRow', LfcApiTableParentRow);
Vue.component('LfcApiTablePagination', LfcApiTablePagination);
Vue.component('LfcMultiSelect', LfcMultiSelect);

// Set up axios defaults and request/response interceptors.
activateAxios();
// configure language
locale.use(lang);

// This passes the inactiveLogout logic to the APIs called from the shared repo. (dev notes: confirm this with test scenario)
Vue.use(Threeflow, { inactiveLogout: inactiveSignOut });

// Create New Pinia
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// Allow for easy/on-the-fly loading of scripts for libraries that expect them in the <HEAD>
Vue.use(LoadScript);

// some third party services we only want to run in non-features environments
const isNonFeaturesEnvironment = config.analyticsEnabled(['production', 'staging', 'qa', 'demo']);

// Use log rocket and segment for certain application environments and local analytics mode.
// Push sentry logs for non local environments.
if (isNonFeaturesEnvironment || canUseThirdParty('logrocket')) {
  LogRocket.init('r2e0ub/broker-site', {
    console: {
      shouldAggregateConsoleErrors: true,
    },
    network: {
      requestSanitizer: (request) => {
        // if the request payload contains 'password'
        if (request?.body?.indexOf('password') !== -1) {
          // Scrub the request body
          // eslint-disable-next-line no-param-reassign
          request.body = null;
        }

        // return the request
        return request;
      },
    },
  });

  LogRocket.getSessionURL((sessionURL) => {
    // send the logrocket url to sentry so we can attach it to tickets
    SentrySetContext('LogRocket', { sessionURL });
    // When you generate the LogRocket session url, we call the Axios Interceptor to pass it on each network call
    addLogRocketURLToHeaders(sessionURL);
  });
  pinia.use(logrocketPlugin);
}

if (isNonFeaturesEnvironment || canUseThirdParty('segment')) {
  initializeSegment(config.VUE_APP_SEGMENT_TOKEN);
}

if (isNonFeaturesEnvironment || canUseThirdParty('sentry')) {
  SentryInit({
    Vue,
    tracePropagationTargets: ['threeflow.com', /^\//],
    dsn: 'https://ccf63cdc34944756912b71c5e881a02f@o97018.ingest.sentry.io/1254241',
    // ignore common non-breaking issues
    ignoreErrors: [
      /request failed with status code 401/i,
      /a network error occurred/i,
      /via a navigation guard/i,
      /non-error promise rejection captured/i,
      'TypeError: convertUTCDateToLocalDate',
      'TypeError: Cannot read properties of null (reading \'charAt\')',
      'NavigationDuplicated',
      'Failed to execute \'getComputedStyle\' on \'Window\': parameter 1 is not of type \'Element\'',
      'ResizeObserver loop limit exceeded',
      /scrollLeft/i,
      /scrollTop/i,
    ],
    integrations: [
      new SentryBrowserTracing({
        routingInstrumentation: SentryVueRouterInstrumentation(router),
        logErrors: true,
      }),
    ],
    environment: appEnvironment,
    release: `broker_ui-${appEnvironment}@${appVersion}`,
    // Stop 401 errors from being sent to sentry by returning null to discard the event entirely
    beforeSend: (event, { captureContext }) => (
      captureContext?.error?.response?.status === 401 ? null : event
    ),
  });
}

// Initialize Launch Darkly
getHealthCheck()
  .then((response) => {
    // HealthCheck is the only endpoint that's not parsed by the interceptor, because, when the interceptor fires,
    // Vue isn't initialized yet. So, we slightly double it up and do it here instead.
    healthCheckCompleted = true;
    launchDarklyConfigData = parseLaunchDarklyConfiguration(response);
  })
  .catch(() => {
    healthCheckCompleted = false;
    // If the health check fails, then we can't initialize LaunchDarkly because we don't have the clientSideId and userInfo required to initialize LD in Secure Mode with an anonymous user
    // but we still need featureFlag values, so we pass "flagsStub" to initializeLaunchDarkly, thus still serving default flag values as defined in the featureFlags file.
    // A failed health check will result in various console errors which are not suppressed, since the app will still run but with defaultFlag values (which is no bueno)
    launchDarklyConfigData = { flagsStub: true };
  })
  .finally(() => {
    initializeLaunchDarkly(launchDarklyConfigData);

    new Vue({
      components: {
        App,
        Icon,
      },
      filters,
      computed: {
        ...mapWritableState(useLaunchDarkly, ['configuration']),
        ...mapWritableState(useCoreApi, ['isHealthy']),
      },
      created() {
        this.isHealthy = healthCheckCompleted;
        this.configuration = launchDarklyConfigData;
      },
      router,
      pinia,
      render: (h) => h(App),
    }).$mount('#app');
  });

if (window.Cypress) {
  // Add setStorage function to window.Cypress as persistMockSignIn so that the mockSignIn command can properly set localStorage and cookies
  window.Cypress.persistMockSignIn = setStorage;
}
