import { tfFetch } from '@/utils/services.js';
import { config } from '@/utils/config.js';
/**
 * API Service used to grab any Headers prior to sign in.
 *
 * @vuedoc
 * @exports getHealthCheck
 * @category Services
 */

/**
 * Hit the health check endpoint on the API in order to grab Headers without side effects.
 *
 * @returns {Promise}
 */
export function getHealthCheck() {
  return tfFetch(`${config.VUE_APP_API_URL}/health_check`);
}
