import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import router from '@/router.js';
import { parseLaunchDarklyConfiguration } from '@/utils/launchDarklyConfig.js';
import { useLaunchDarkly } from '@/stores/launchDarkly.js';
// eslint-disable-next-line import/no-cycle
import { useUser } from '@/stores/user.js';
import { getAxiosConfig, setCookie } from '@watchtowerbenefits/es-utils-public';
import { config as appConfig } from '@/utils/config.js';

/**
 * Combine the environment's VUE_APP_API_URL configuration to return the base URL for all APIs.
 *
 * @returns {string}
 */
export const apiUrl = `${appConfig.VUE_APP_API_URL}/v1/broker_portal`;

/**
 * Return the environment's cookie namespace to be used for all APIs.
 *
 * @returns {string}
 */
export const cookieNameSpace = appConfig.VUE_APP_COOKIE_NAMESPACE;

/**
 * Namespace the auth token with the appropriate cookie.
 *
 * @returns {string}
 */
export const cookieAuthToken = `${cookieNameSpace}-auth-token`;

/**
 * Namespace the user id with the appropriate cookie.
 *
 * @returns {string}
 */
export const cookieUserId = `${cookieNameSpace}-user-id`;

/**
 * A customized axios method that returns response or throws an error. This is used when the full response schema and error handling are handled elsewhere.
 * The supported order of parameters is:
 * - method: This is 'get' by default so is not required
 * - url
 * - data payload: This is only required for non-get/delete requests.
 * - request configs: https://axios-http.com/docs/req_config.
 *
 * @param {...any} config
 * @returns {Promise}
 */
export function tfFetch(...config) {
  const methodBypassed = !['patch', 'post', 'delete', 'get'].includes(config[0]);
  const method = methodBypassed ? 'get' : config[0];
  let requestConfigs = {};

  if (['get', 'delete'].includes(method)) {
    requestConfigs = methodBypassed ? config[1] : config[2];
  } else {
    [, , , requestConfigs] = config;
  }

  return axios({
    method,
    url: methodBypassed ? config[0] : config[1],
    data: ['get', 'delete'].includes(method) ? {} : config[2],
    ...requestConfigs,
  });
}

/**
 * A common method for the axios functions that don't require any data in axios' request config and returns the response data.
 *
 * @param {string} method
 * @param {string} url
 * @param {string} config
 * @returns {object}
 */
async function getAxiosDataWithoutPayload(method, url, config) {
  const { data } = await axios({ method, url, ...config });

  return data;
}

/**
 * Custom "get" that returns data or throws an error.
 * [axios configs](https://axios-http.com/docs/req_config).
 *
 * @param {string} url
 * @param {object} config
 * @returns {Promise}
 */
export async function tfGet(url, config) {
  return getAxiosDataWithoutPayload('get', url, config);
}

/**
 * Custom "delete" that returns data or throws an error.
 * [axios configs](https://axios-http.com/docs/req_config).
 *
 * @param {string} url
 * @param {object} config
 * @returns {Promise}
 */
export async function tfDelete(url, config) {
  return getAxiosDataWithoutPayload('delete', url, config);
}

/**
 * A common method for the axios functions that require data in axios' request config and returns the response data.
 *
 * @param {string} method
 * @param {string} url
 * @param {string} payload
 * @param {string} config
 * @returns {object}
 */
async function getAxiosDataWithPayload(
  method,
  url,
  payload = {},
  config = {},
) {
  const { data } = await axios({
    method,
    url,
    data: payload,
    ...config,
  });

  return data;
}

/**
 * Custom "patch" that returns data or throws an error.
 * [axios configs](https://axios-http.com/docs/req_config).
 *
 * @param {string} url
 * @param {object} payload
 * @param {object} config
 * @returns {Promise}
 */
export async function tfPatch(url, payload, config) {
  return getAxiosDataWithPayload('patch', url, payload, config);
}

/**
 * Custom "post" that returns data or throws an error.
 * [axios configs](https://axios-http.com/docs/req_config).
 *
 * @param {string} url
 * @param {object} payload
 * @param {object} config
 * @returns {Promise}
 */
export async function tfPost(url, payload, config) {
  return getAxiosDataWithPayload('post', url, payload, config);
}

/**
 * Custom "put" that returns data or throws an error.
 * [axios configs](https://axios-http.com/docs/req_config).
 *
 * @param {string} url
 * @param {object} payload
 * @param {object} config
 * @returns {Promise}
 */
export async function tfPut(url, payload, config) {
  return getAxiosDataWithPayload('put', url, payload, config);
}

/**
 * Custom "delete" that accepts a payload returns data or throws an error.
 * [axios configs](https://axios-http.com/docs/req_config).
 *
 * @param {string} url
 * @param {object} payload
 * @param {object} config
 * @returns {Promise}
 */
export async function tfDeleteWithPayload(url, payload, config) {
  return getAxiosDataWithPayload('delete', url, payload, config);
}

/**
 * Set the local storage and cookies for the user.
 *
 * @param {object} user
 */
export function setStorage(user = {}) {
  return new Promise((resolve) => {
    setCookie(cookieAuthToken, user.auth_token, new Date(user.auth_token_expires_at));

    window.localStorage.setItem('isAuthorized', true);
    window.localStorage.setItem('email', user
      .email);
    window.localStorage.setItem('id', user.id);

    resolve();
  });
}

/**
 * Method to run if the users auth token is invalid/expired
 * We pass this down to the shared repo to run if finds a bad auth token too.
 */
export function inactiveSignOut() {
  const { pathname: redirect } = window.location;

  // When there is multiple APIs run at once, they will return from the interceptor after the user has been sent to the sign in page.
  if (redirect === '/sign-in') {
    return;
  }

  // Set localStorage to false for the SignInPage to show correct toast.
  window.localStorage.setItem('isAuthorized', false);

  router.push({
    name: 'SignInPage',
    query: { redirect },
  });
}

/**
 * Set the `baseURL` for axios, which will be prepended to `config.url` unless `config.url` is absolute.
 * Request headers add axiosConfig from the shared repo into the request header.
 * Response headers sniff for any changes in the launch darkly header info in addition to authentication.
 */
export function activateAxios() {
  axios.defaults.baseURL = apiUrl;
  axios.interceptors.request.use((config) => {
    const { headers: cookieHeaders } = getAxiosConfig(cookieNameSpace);

    return {
      ...config,
      headers: {
        ...cookieHeaders,
        ...config.headers,
      },
    };
  });
  axios.interceptors.response.use(
    (response) => {
      // We let the healthCheck call handle this itself, since it runs before the store is initialized.
      // These conditionals check for any url that contains "core-api" and doesn't contain "health_check"
      // and for any non-health_check API call, it commits the LD config returned from the headers to the store
      if (response.config.baseURL.includes(appConfig.VUE_APP_API_URL) && !response.config.url.includes('health_check')) {
        const launchDarkly = useLaunchDarkly();

        launchDarkly.configuration = parseLaunchDarklyConfiguration(response);
      }

      return response;
    },
    (error = {}) => {
      if (
        error.response?.config?.url
        && error.response?.status
      ) {
        if (
          error.response.status === 401
          && ![`${apiUrl}/sign_in`, '/invitation'].includes(error.response.config.url)
        ) {
          inactiveSignOut();
        } else if (
          error.response.status === 403
        ) {
          const userStore = useUser();

          userStore.accessForbiddenError = 'A server error occurred because you do not have access to this content.';
        }
      }

      return Promise.reject(error);
    },
  );
}

/**
 * Add the LogRocket SessionURL to the headers each time an Axios request is made.
 *
 * @param {string} sessionURL
 */
export function addLogRocketURLToHeaders(sessionURL) {
  axios.interceptors.request.use((config) => ({
    ...config,
    headers: {
      ...config.headers,
      'X-LogRocket-URL': sessionURL,
    },
  }));
}
