import { config } from '@/utils/config.js';
/**
 * Checks to see if the provided name exists within the THIRD_PARTIES environment variable.
 * Finding a match dictates whether or not the third party should be enabled.
 *
 * @param {string} name - The name of a third party library to check for.
 * @returns {boolean} - Whether or not the third party should be enabled.
 */
export const canUseThirdParty = (name) => {
  if (!name) {
    throw new Error('canUseThirdParty requires a name to check for');
  }

  const nameCaseInsensitive = new RegExp(name, 'i');
  const thirdParties = config.VUE_APP_THIRD_PARTIES ?? 'sentry';

  return thirdParties.match(nameCaseInsensitive) !== null;
};
