/**
 * APIs for "/v1/broker_portal/products".
 *
 * @vuedoc
 * @exports product
 * @category Services
 */

import {
  tfDelete,
  tfGet,
  tfPatch,
  tfPost,
} from '@/utils/services.js';

const base = 'products';

/**
 * Set sold state of a product to be sold or not sold based on the params.
 *
 * @param {number} productId
 * @param {boolean} isSold
 * @returns {Promise}
 */
export function setProductSoldState(productId, isSold) {
  const appendUrl = isSold ? 'mark_as_sold' : 'mark_as_not_sold';

  return tfPatch(`${base}/${productId}/${appendUrl}`, { is_sold: isSold });
}
/**
 * Get the rate structure for a given product.
 *
 * @param {number} productId
 * @returns {Promise}
 */
export function getRateStructure(productId) {
  return tfGet(`${base}/${productId}/rate_structure`);
}
/**
 * Get Plan Details (notes) for a given product.
 *
 * @param {number} productId
 * @returns {Promise}
 */
export function getPlanDetails(productId) {
  return tfGet(`${base}/${productId}`);
}
/**
 * Get Plan Design for a given product.
 *
 * @param {number} productId
 * @returns {Promise}
 */
export function getPlanDesign(productId) {
  return tfGet(`${base}/${productId}/plan_design`);
}
/**
 * Delete a product.
 * THIS IS ONLY INTENDED TO BE USED FOR ALTERNATIVES, AND DURING ClientEvents!
 * Check with a BE developer if there is any intent to use it for another purpose.
 *
 * @param {number} productId
 * @returns {Promise}
 */
export function deleteProduct(productId) {
  return tfDelete(`${base}/${productId}`);
}
/**
 * Set a value on a product based on the passed parameters.
 *
 * @param {object} newValue
 * @param {number|string} newValue.productId - The product ID or inforce product ID.
 * @param {string} newValue.prop
 * @param {string|number} newValue.value
 * @returns {Promise}
 */
export function setProductValue({ productId, prop, value }) {
  return tfPatch(`${base}/${productId}`, { [prop]: value });
}
/**
 * Update one or more values on a product based on the passed parameters.
 *
 * @param {number} productId
 * @param {object} updatedValues
 * @returns {Promise}
 */
export function updateProductValues(productId, updatedValues) {
  return tfPatch(`${base}/${productId}`, updatedValues);
}
/**
 * Set a plan design value on a product based on the passed parameters.
 *
 * @param {object} updatedValue
 * @param {number|string} updatedValue.productId
 * @param {Array} updatedValue.planDesignValues
 * @returns {Promise}
 */
export function patchProductPlanDesignValue({ productId, planDesignValues }) {
  return tfPatch(
    `${base}/${productId}/plan_design_values`,
    { plan_design_values: planDesignValues },
  );
}

/**
 * Archive/dearchive a product.
 *
 * @param {number} productId
 * @param {boolean} archived
 * @returns {Promise}
 */
export function setProductArchivedState(productId, archived) {
  return tfPatch(`${base}/${productId}`, { archived });
}
/**
 * Change the position of the product within the project products listing.
 *
 * @param {number} productId
 * @param {number} position
 * @returns {Promise}
 */
export function setProductPosition(productId, position) {
  return tfPatch(`${base}/${productId}`, { position });
}
/**
 * Get the normalized values for a given product.
 *
 * @param {number} productId
 * @returns {Promise}
 */
export function getNormalizedValues(productId) {
  return tfGet(`${base}/${productId}/normalized_values`);
}
/**
 * Set the rate guarantee for the project.
 *
 * @param {number} productId
 * @param {string|number} newRateGuaranteeValue
 * @returns {Promise}
 */
export function setRateGuaranteeValue(productId, newRateGuaranteeValue) {
  return tfPatch(`${base}/${productId}/rate_guarantee`, { value: newRateGuaranteeValue });
}
/**
 * Set the rate basis for a product's rates.
 *
 * @param {number} productId
 * @param {Array} containerIds
 * @param {number} attributeId
 * @param {string|number} newRateBasisValue
 * @param {number} tierGroupId
 * @param {number} tierSubtypeId
 * @returns {Promise}
 */
export function setRateBasisValue(
  productId,
  containerIds,
  attributeId,
  newRateBasisValue,
  tierGroupId = null,
  tierSubtypeId = null,
) {
  return tfPatch(`${base}/${productId}/rate_attributes/${attributeId}/rate_basis`, {
    container_ids: containerIds,
    rate_basis: newRateBasisValue,
    tier_group_id: tierGroupId,
    tier_subtype_id: tierSubtypeId,
  });
}

/**
 * Create a new container for the given inforce product ID.
 *
 * @param {number} inforceProductId
 * @param {number} containerType
 * @param {string} description
 * @returns {Promise}
 */
export function createContainer(inforceProductId, containerType, description) {
  return tfPost(`${base}/${inforceProductId}/containers`, { project_products_container_type_id: containerType, description });
}
