import { defineStore } from 'pinia';

export const useLaunchDarkly = defineStore('launchDarkly', {
  state: () => ({
    configuration: {},
  }),
  actions: {
    resetLaunchDarkly() {
      this.$reset();
    },
  },
});
