/**
 * A set of short-hand RBAC application-level roles
 * and the user record `roles` values that will
 * enable these app roles.
 *
 * @exports roles
 * @category Utils
 */

export const appRoles = {
  CM: ['admin', 'client_management'],
  PM: ['admin', 'placement_management'],
  AP: ['admin', 'advanced_placement'],
  BM: ['admin', 'broker_management'],
  IS: ['admin', 'insights'],
};
