<template>
  <!-- eslint-disable vue/no-template-target-blank -->
  <AppButton
    class="cta-email-support"
    href="mailto:support@threeflow.com"
    size="text"
    tag="a"
    target="_blank"
    :type="type"
    :no-branding="noBranding"
  >
    <slot>
      contact support
    </slot>
  </AppButton>
</template>
<script>
  /**
   * "CtaEmailSupport" is a simple call-to-action component used for opening a user's email client (if set) with the appropriate support email address.
   *
   * @vuedoc
   * @exports AppCtaEmailSupport
   * @category Components
   */
  export default {
    name: 'AppCtaEmailSupport',
    props: {
      /**
       * Boolean used to tell the AppButton component to bypass branding styles.
       */
      noBranding: {
        type: Boolean,
        default: false,
      },
      /**
       * String used to tell the AppButton component what button type/color to display.
       */
      type: {
        type: String,
        default: 'primary',
      },
    },
  };
</script>
