/**
 * Waits until a condition is met (usually a function that returns a boolean).
 *
 * @param {Function} waitFor // The condition to wait for.
 * @param {number} waitTime // Time in milliseconds to wait between checks.
 * @returns {Promise}
 */
export const waitUntil = (waitFor = () => (false), waitTime = 100) => new Promise((resolve, reject) => {
  const checkReady = (count = 1) => {
    if (waitFor()) {
      resolve();
    } else if (count < 20) {
      setTimeout(() => checkReady(count + 1), waitTime);
    } else {
      reject(new Error('Ready check timed out.'));
    }
  };

  checkReady();
});
