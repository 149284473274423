<template>
  <TfTooltip
    v-bind="{
      ...$attrs,
      ...$props,
    }"
    :options="{
      placement,
      modifiers: {
        offset: { offset },
      },
    }"
    :delay-on-mouse-out="delayOnMouseOut"
    v-on="$listeners"
  >
    <template slot="reference">
      <slot name="reference" />
    </template>
    <slot />
  </TfTooltip>
</template>

<script>
  /**
   * A component that wraps the Shared TfTooltip component with the most common props and settings used on the Broker app.
   *
   * @vuedoc
   * @exports AppTooltip
   * @category Components
   */
  export default {
    name: 'AppTooltip',
    inheritAttrs: false,
    props: {
      delayOnMouseOut: {
        type: Number,
        default: 30,
      },
      placement: {
        type: String,
        default: 'bottom',
      },
      offset: {
        type: String,
        default: '0,4px',
      },
    },
  };

</script>

<style scoped lang="scss">
  :deep(.tf-tooltip) {
    padding: 5px 8px;
    text-align: left;
    line-height: 18px;
    white-space: normal;
  }
</style>
