import { tfPatch, tfPost } from '@/utils/services.js';

/**
 * APIs for "/v1/broker_portal/current_coverages".
 *
 * @vuedoc
 * @exports currentCoverages
 * @category Services
 */

/**
 * PATCH the current coverage plan design value based on the pass parameters.
 *
 * @param {number|string} id
 * @param {Array} planDesignValues
 * @returns {Promise}
 */
export function patchCurrentCoveragePlanDesignValue(id, planDesignValues) {
  return tfPatch(`current_coverages/${id}/plan_design_values`, {
    plan_design_values: planDesignValues,
  });
}

/**
 * Get all current coverages for a broker.
 *
 * @returns {Promise}
 */
export function getBrokerCurrentCoverages() {
  return tfPost('current_coverage_dashboard/view');
}
