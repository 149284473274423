<template>
  <v-date-picker
    v-bind="{
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      popover: {
        visibility: 'focus',
      },
      ...$attrs,
      ...$props,
    }"
    ref="datePicker"
    trim-weeks
    v-on="$listeners"
  >
    <template
      v-if="isInput || isInlineFormField"
      #default="{
        inputValue,
        inputEvents,
      }"
    >
      <!-- Pass new input here ?-->
      <input
        v-if="isInput"
        :value="inputValue"
        data-test="edit date"
        placeholder="mm/dd/yy"
        v-on="inputEvents"
      >
      <div
        v-else-if="isInlineFormField"
        :class="[
          { 'is-hovering': isHovering },
          { 'is-editing': isEditing },
          'inline-form-field',
        ]"
      >
        <input
          :value="inputValue"
          data-test="edit date"
          placeholder="mm/dd/yy"
          v-on="inputEvents"
          @focus="isEditing = true"
          @blur="onBlur(inputValue, $event)"
        >
        <AppButton
          size="icon"
          icon="fa-solid fa-pencil"
          class="btn-edit"
          @click="onBtnClick"
        />
      </div>
    </template>
  </v-date-picker>
</template>
<script>
  import dayjs from 'dayjs';
  /**
   * 1) Default view: The date picker is always visible.
   * 2) isInline: This shows a styled input that will toggle the calendar when clicked.
   * 3) isInlineFormField: This renders a flavor or https://components.threeflow.com/examples/form-elements-storybook-layout/inline-form-field.
   *
   * V-calendar provides an array of options including popover specific props which are passed in a nested popover object.
   * Popover specific props are listed here: https://vcalendar.io/api/v1.0/datepicker.html#:~:text=%23-,popover,-Type%3A%20Object.
   *
   * @vuedoc
   * @exports AppDatePicker
   * @category Components
   */

  export default {
    name: 'AppDatePicker',
    inheritAttrs: false,
    props: {
      /**
       * Determines the hover state if the `isInlineFormField: true`.
       */
      isHovering: {
        type: Boolean,
        default: false,
      },
      /**
       * Determines if the calendar is toggled on input click.
       */
      isInput: {
        type: Boolean,
        default: false,
      },
      /**
       * Determines if the calendar interactions should mimic https://components.threeflow.com/examples/form-elements-storybook-layout/inline-form-field..
       */
      isInlineFormField: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      isEditing: false,
    }),
    methods: {
      /**
       * Handles the click event on the edit button when `isInlineFormField: true`.
       */
      onBtnClick() {
        this.isEditing = true;

        this.$refs.datePicker.showPopover();
      },
      /**
       * When blurring from the input, prevent the calendar closing if the user clicked within the v-calendar component.
       *
       * @param {string} value
       * @param {object} event
       */
      onBlur(value, event) {
        const classList = event.relatedTarget?.classList;

        /**
         * "vc-popover-content" is the class in the popover header.
         * "vc-day-content" is the class of the day in the calendar.
         */
        if (
          classList
          && (
            classList.contains('vc-day-content')
            || classList.contains('vc-popover-content')
          )
        ) {
          /**
           * Clicking on a day in the calendar emits 'daychange' and handled by the parent. This will change the input editing state.
           */
          if (classList.contains('vc-day-content')) {
            this.isEditing = false;
          }

          return;
        }

        this.isEditing = false;
        this.$emit(
          'blur',
          dayjs(value).format('YYYY-MM-DD'),
        );
        this.$refs.datePicker.hidePopover();
      },
    },
  };
</script>

<style lang="scss" scoped>
  input {
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: var(--tf-border-radius);
    border: solid 1px var(--tf-gray-medium);
    height: var(--ElInput-height);
    line-height: var(--ElInput-height);
  }

  .inline-form-field {
    display: flex;
    flex: 1;
    align-items: center;
    cursor: pointer;

    input {
      box-sizing: border-box;
      padding: 0;
      border: 1px solid transparent;
      transition: all .5s;
      overflow: hidden;
    }

    &:hover,
    &.is-hovering,
    &.is-editing {
      .btn-edit {
        opacity: 1;
      }

      input {
        padding: 0 10px 0 15px;
        border-color: var(--tf-border);
        background: var(--tf-base-light);
      }
    }

    &.is-editing {
      .btn-edit {
        display: none;
      }
    }
  }

  .btn-edit {
    opacity: 0;
    transition: all .5s;
  }
</style>
