import Vue from 'vue';
import nmd from 'nano-markdown';

/**
 * Utility file for vue filters.
 *
 * @exports filters
 * @category Utils
 */
export default {
  ageBanded: Vue.filter('ageBanded', (value) => {
    if (value === 'age_80_plus') {
      return '80+';
    }

    return value.replace('age_', '').replace('_', '-');
  }),
  /**
   * Iterate through the passed value to create a string consisting of the uppercased value items separated by a comma.
   *
   * @param {Array} value
   * @returns {string}
   */
  friendlyFileFormat: Vue.filter('friendlyFileFormat', (value) => (
    value
      .join(', ')
      .replace(/[.]/g, '')
      .toUpperCase()
  )),
  capitalize: Vue.filter('capitalize', (value) => value.charAt(0).toUpperCase() + value.slice(1)),
  lowercase: Vue.filter('lowercase', (value) => value.toLowerCase()),
  nmd: Vue.filter('nmd', (value) => {
    let newValue = '';

    if (value) {
      newValue = nmd(value).replace(/<hr\/>/g, '');
    }

    return newValue;
  }),
  noOrphan: Vue.filter('noOrphan', (value) => value.replace(/ (?=[^ ]*$)/i, '&amp;nbsp;')),
  sourceType: Vue.filter('sourceType', (value) => {
    let sourceType;

    switch (value) {
      case 'CensusSource':
        sourceType = 'Census';
        break;
      case 'ExperienceSource':
      case 'StopLoss::ExperienceSource':
        sourceType = 'Experience';
        break;
      case 'PolicySource':
        sourceType = 'Policy & Certificates';
        break;
      case 'StopLoss::PolicySource':
        sourceType = 'Plan & Stop Loss policy';
        break;
      case 'RateSource':
      case 'StopLoss::RateSource':
        sourceType = 'Rates';
        break;
      default:
    }

    return sourceType;
  }),
  tierGroupName: Vue.filter('tierGroupName', (value) => {
    let name = value;

    switch (value) {
      case 'CompositeRateValue':
        name = 'Composite';
        break;
      case 'AgeBandedRateValue':
        name = 'Age Band';
        break;
      case 'CustomRateValue':
        name = 'Custom';
        break;
      default:
        name = value;
    }

    return name;
  }),
  cleanSpecialCharacters: Vue.filter('cleanSpecialCharacters', (value) => (
    value?.toLowerCase().trim().replace(/[^A-Z]+/ig, '_'))),
};
