<template>
  <div
    id="app"
    v-loading="!isLoaded"
    :class="{ 'authentication-page': $route.meta.noAuthRequired }"
  >
    <template v-if="isLoaded">
      <UpdatePassword v-if="user.temp_password && isUserLoaded" />
      <AppAlert
        v-if="isMarkSoldLoaded && markSoldAlertIsVisible"
        type="info"
        data-test="opportunities to mark sold disclaimer"
        data-test-close="close opportunities to mark sold disclaimer"
        class="opportunities-alert"
        closable
        @close="markSoldAlertIsVisible = false"
      >
        {{ productsReadyToBeMarkedSoldText }} ready to be marked as sold!
        <RouterLink
          :to="{
            name: 'AllCurrentCoverages',
            query: { filters: JSON.stringify({ status: ['Pending mark sold'] }) },
          }"
          @click.native="markSoldAlertIsVisible = false"
        >
          <AppButton
            size="text"
            text="View coverages"
          />.
        </RouterLink>
      </AppAlert>
      <RouterView />
    </template>
  </div>
</template>

<script>
  import { useUser } from '@/stores/user.js';
  import {
    mapActions,
    mapState,
    mapWritableState,
  } from 'pinia';
  import { signOut } from '@/apis/account.js';
  import { cookieAuthToken } from '@/utils/services.js';
  import { waitUntil } from '@/utils/common.js';
  import { useMarkSold } from '@/stores/markSold.js';
  import { getCookie } from '@watchtowerbenefits/es-utils-public';

  export default {
    name: 'App',
    components: {
      UpdatePassword: () => import('@/components/Modals/UpdatePassword.vue'),
    },
    data: () => ({
      isLoaded: false,
      markSoldAlertIsVisible: false,
    }),
    computed: {
      ...mapState(useUser, { isUserLoaded: 'isLoaded' }),
      ...mapState(useUser, ['user']),
      ...mapWritableState(useUser, ['accessForbiddenError']),
      ...mapState(useMarkSold, [
        'productsReadyToBeMarkedSold',
        'productsReadyToBeMarkedSoldPastDue',
      ]),
      ...mapState(useMarkSold, { isMarkSoldLoaded: 'isLoaded' }),
      /**
       * Combine the number of opportunities ready to be marked sold with and appropriate text used for the `markSoldAlert` toast message.
       *
       * @returns {object}
       */
      productsReadyToBeMarkedSoldText() {
        return `${this.productsReadyToBeMarkedSoldPastDue} ${this.productsReadyToBeMarkedSoldPastDue === 1 ? 'coverage' : 'coverages'}`;
      },
    },
    watch: {
      /**
       * Notify the user when there are product ready to be marked sold.
       *
       * @param {string} newValue
       */
      productsReadyToBeMarkedSoldPastDue(newValue) {
        if (newValue) {
          // dev.notes: Turning this off for v1 of feature. Will turn back on with https://watchtower.atlassian.net/browse/BPL-2833
          // this.markSoldAlertIsVisible = true;
          this.markSoldAlertIsVisible = false;
        }
      },
      /**
       * Watch to see whether the User has received a 403 Forbidden error from the BE upon attempting to access something their role
       * does not have access to.
       *
       * @param {boolean} newValue
       */
      accessForbiddenError(newValue) {
        if (newValue) {
          this.$message({
            duration: 5000,
            message: this.accessForbiddenError,
            showClose: true,
            type: 'error',
          });

          this.accessForbiddenError = '';
        }
      },
    },
    async created() {
      this.setUserAgentClasses();

      if (
        window.localStorage.getItem('isAuthorized') === 'true'
        && getCookie(cookieAuthToken)
        && window.localStorage.getItem('id') !== ''
      ) {
        await this.loadUserInfo(window.localStorage.getItem('id'));
      }
      try {
        // Wait until the user info has been loaded and the LaunchDarkly client is ready before setting
        // isLoaded to true. If the LD Client isn't ready, then we can't retrieve feature flags yet.
        await waitUntil(() => (this.$ld.ready));
        this.isLoaded = true;
      } catch {
        signOut();
      }
    },
    methods: {
      ...mapActions(useUser, ['loadUserInfo']),
      /**
       * Add some classes to the html element so we can use IE-only CSS.
       */
      setUserAgentClasses() {
        const { userAgent } = window.navigator;
        const edge = userAgent.indexOf('Edge/');
        const htmlClasses = [];
        const html = document.getElementsByTagName('html')[0];

        if (edge > 0) {
          htmlClasses.push('is-edge');
          htmlClasses.push(`is-edge-${parseInt(userAgent.substring(edge + 5, userAgent.indexOf('.', edge)), 10)}`);
        }

        if (htmlClasses.length > 0) {
          html.className = htmlClasses.join(' ');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .opportunities-alert {
    position: absolute !important; // stylelint-disable-line declaration-no-important
    z-index: 1;
    left: 0;
    right: 0;
    top: 30px;
  }
</style>
